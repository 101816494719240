import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const YouTube = makeShortcode("YouTube");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "creating-content-training-video"
    }}>{`Creating Content Training Video`}</h1>
    <hr></hr>
    <p>{`In this quick tutorial, learn how to effortlessly create content to organize and best showcase your app. `}<em parentName="p">{`Please note this video is created through the lense of a user who doesn't have permissions to schedule content. For users who have this permission, an additional screen will appear to schedule the item. How to schedule a content item is covered in the `}<a parentName="em" {...{
          "href": "https://appdocs.sol.us/schedule-a-content-item-"
        }}>{`Schedule a Content Item`}</a>{` help article.`}</em></p>
    <YouTube mdxType="YouTube"><iframe src="//www.youtube.com/embed/lDulqJkxGwU?si=HaDUO1nPWReHts16" frameBorder="0" allowFullScreen style={{
        "position": "absolute",
        "top": "0: left: 0",
        "width": "90%",
        "height": "90%"
      }}></iframe></YouTube>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      